import consts from "../consts";
import { Reflect } from "@workcanvas/reflect/client";
import { ReadTransaction, WriteTransaction } from "@workcanvas/reflect";
import { M } from "../datamodel/mutators";
import { FontProperties } from "../datamodel/schemas/textEnabled";
import { Board, BoardPermission, Flags } from "../datamodel/schemas";
import { customAlphabet } from "nanoid";

export function randInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
}

export const canvasElementPrefix = "cElement-";
export const canvasMetadataPrefix = "cMetadata-";
export const sanityMutationId = 'sanity-mutation';

let fontSizeToPixelMapping: { [key: string]: number } = {};
let widthSizeToPixelMapping: { [key: string]: number } = {};
let fontStyleToString: { [key: number]: string } = {};

function initFontSizeToPixelMapping() {
  fontSizeToPixelMapping[consts.TEXT_SIZES.LARGE] = 80;
  fontSizeToPixelMapping[consts.TEXT_SIZES.MEDIUM] = 55;
  fontSizeToPixelMapping[consts.TEXT_SIZES.SMALL] = 40;
}

function initWidthSizeToPixelMapping() {
  widthSizeToPixelMapping[consts.WIDTH_SIZES.LARGE] = 4;
  widthSizeToPixelMapping[consts.WIDTH_SIZES.SMALL] = 2;
}

function initFontStyleToString() {
  fontStyleToString[FontProperties.None] = "normal";
  fontStyleToString[FontProperties.Bold] = "bold";
  fontStyleToString[FontProperties.Italic] = "italic";
  fontStyleToString[FontProperties.Bold | FontProperties.Italic] = "italic bold";
}

initFontSizeToPixelMapping();
initWidthSizeToPixelMapping();
initFontStyleToString();

export function getUnixTimestampUTC() {
  return Date.now();
  //return Math.floor((new Date()).getTime());
}

export function fontSizeToPixels(size: string) {
  if (Object.values(consts.TEXT_SIZES).includes(size)) {
    return fontSizeToPixelMapping[size];
  } else {
    console.error("invalid font size received");
  }
}

export function pixelsToFontSize(pixelSize: number) {
  const values = Object.values(fontSizeToPixelMapping);
  if (values.includes(pixelSize)) {
    const index = values.indexOf(pixelSize);
    return Object.keys(fontSizeToPixelMapping)[index];
  } else {
    console.error("invalid font size received");
  }
}

export function parseStrokeWidth(strokeWidth: string | number) {
  if (typeof strokeWidth === "number") return strokeWidth;
  if (Object.values(consts.WIDTH_SIZES).includes(strokeWidth)) {
    return widthSizeToPixelMapping[strokeWidth];
  }
  console.error("invalid stroke width received");
  return 0;
}

export function sizeToPixel(size: string, type: "font" | "width") {
  const mapping = type === "font" ? fontSizeToPixelMapping : type === "width" ? widthSizeToPixelMapping : null;
  const validSizes = type === "font" ? consts.TEXT_SIZES : type === "width" ? consts.WIDTH_SIZES : {};
  if (mapping && Object.values(validSizes).includes(size)) {
    return mapping[size];
  } else {
    console.error("invalid font size received", size, type);
  }
}

export function pixelsToSize(pixelSize: number, type: string) {
  const mapping = type === "font" ? fontSizeToPixelMapping : type === "width" ? widthSizeToPixelMapping : [];
  const values = Object.values(mapping);
  if (values.includes(pixelSize)) {
    const index = values.indexOf(pixelSize);
    return Object.keys(fontSizeToPixelMapping)[index];
  } else {
    console.error("invalid font size received");
  }
}

export function fontPropertiesToString(props: FontProperties): string {
  return fontStyleToString[props & FontProperties.StyleBits];
}

export function konvaTextDecoration(props: FontProperties): string {
  const ul = FontProperties.Underline;
  const lt = FontProperties.LineThrough;

  if ((props & (ul | lt)) == (ul | lt)) return "underline line-through";
  if (props & ul) return "underline";
  if (props & lt) return "line-through";
  return "";
}

export function toNumber(s?: string | number | null, defaultValue = 0): number {
  if (s === null || s === undefined) return defaultValue;
  if (typeof s === "number") return s;
  const num = parseInt(s, 10);
  return isNaN(num) ? defaultValue : num;
}

export function dbKey(id: string, type: string) {
  return `${type}-${id}`;
}

export function toTypedRep(rep: Reflect<M>) {
  return rep as Reflect<M>;
}

export function validateSchema(schema: { parse: (value: any) => any }, value: any) {
  return { ...value }; //return schema.parse(value);
}

export const textEnabledDefaults = Object.freeze({
  text: "",
  textColor: consts.DEFAULTS.TEXT_COLOR,
  fontSize: consts.DEFAULTS.FONTSIZE,
  font: consts.DEFAULTS.FONT,
  cursorPosition: 0,
  align: consts.DEFAULTS.TEXT_ALIGN,
  fontProps: 0,
});

// function from https://stackoverflow.com/a/15832662/512042
// TODO: move to frontend utils, backend never needs this
export function downloadURI(uri: string, name: string) {
  var link = document.createElement("a");
  link.download = name;
  link.href = uri;
  link.rel = "noopener"; // to prevent the download from opening a new tab
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export async function authenticatedPut(tx: WriteTransaction, key: string, value: any) {
  if (tx.location === "client" || !tx.auth?.isReadOnly) {
    return tx.set(key, value);
  }
}

export async function authenticatedDelete(tx: WriteTransaction, key: string) {
  if (tx.location === "client" || !tx.auth?.isReadOnly) {
    return tx.del(key);
  } else {
    return false;
  }
}

export function isReadOnlyBoard(board: Board | null | undefined, isAnonymousUser: boolean | undefined): boolean {
  if (board?.isDowngraded) return true; //for a downgrade case, bypass all the other conditions
  if (board?.isOwner) return false;
  switch (board?.permission) {
    case BoardPermission.private:
      return Boolean(board?.isReadOnly);
    case BoardPermission.public:
      return false;
    case BoardPermission.publicReadOnly:
      return isAnonymousUser ? true : Boolean(board?.isReadOnly);
    default:
      return true;
  }
}

const isbitOn = (value: number, mask: number) => (value & mask) == mask;

/**
 * Check if certain element counts towards the limit for basic-plan users
 */
export const isElementNumberLimited = ({
  type,
  hidden = false,
  flags = 0,
}: {
  type: string;
  hidden?: boolean;
  flags?: number;
}) => {
  if (
    hidden ||
    isbitOn(flags, Flags.CreatedBySystem) ||
    type == consts.CANVAS_ELEMENTS.DRAWING ||
    type == consts.CANVAS_ELEMENTS.COMMENT
  ) {
    return false;
  }
  return true;
};

/**
 * Count the number of elements currently in the canvas that count towards the limit for basic-plan users
 */
export async function countCanvasElements(rep: Reflect<M>) {
  return rep.query(async (tx: ReadTransaction) => {
    let count = 0;
    for await (const value of tx.scan({ prefix: canvasElementPrefix })) {
      const element = value as any;
      if (isElementNumberLimited(element)) count++;
    }
    return count;
  });
}

const nanoid = customAlphabet("0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ", 21);

export function createElementId() {
  return nanoid();
}

export function cleanAccountName(teamName: string) {
  try {
    const workCanvasPrefixRegex = /^(workCanvas )/;
    teamName = teamName.replace(workCanvasPrefixRegex, "").trim();
    const accountRegex = / account/gi;  
    const newTeamName = teamName.replace(accountRegex, "");
    return newTeamName.charAt(0).toUpperCase() + newTeamName.slice(1);
  } catch (error: any) {
    return teamName;
  }
}
