import { boardSchema } from "shared/datamodel/schemas/board";
import useBoard from "frontend/hooks/use-board";
import React, { createContext, useContext, useEffect, useReducer, useMemo } from "react";
import { randomUserDecoration } from "shared/datamodel/client-state";
import { User } from "shared/datamodel/schemas/user";
import { z } from "zod";
import LoadedState from "./loadedState";
import useStateValue from "./value";
import { BoardUser, boardUserSchema } from "shared/datamodel/schemas/board-user";

const boardConfig = z.object({
  isOpenSession: z.boolean(),
  isViewOnly: z.boolean(),
});
export type BoardConfig = z.infer<typeof boardConfig>;
export const defaultBoardConfig: BoardConfig = { isOpenSession: false, isViewOnly: false };

const boardStateSchema = z.object({
  documentId: z.string(),
  isReady: z.boolean().default(false),
  user: boardUserSchema.nullish(),
  isAnonymous: z.boolean().nullish(),
  loadingUser: z.boolean().default(true),
  board: boardSchema.nullish(),
  failedLoadingBoard: z.boolean().default(false),
  isDeletedBoard: z.boolean().default(false),
  isStageReady: z.boolean().default(false),
  stageRef: z.any().optional(),
  config: boardConfig,
  expiresInHours: z.number().optional(),
  showsSignup: z.boolean().default(false),
  templateElementsToCreate: z.record(z.string(), z.any()).nullish(),
  pusherChannel: z.any().optional(),
});
export type BoardState = z.infer<typeof boardStateSchema>;

function initialState(documentId: string): BoardState {
  return boardStateSchema.parse({
    documentId,
    config: { isOpenSession: false, isViewOnly: false },
  });
}

type BoardDispatch = { type: string; payload: any | null };

function reducer(state: BoardState, action: BoardDispatch): BoardState {
  switch (action.type) {
    case "board_loaded":
      return {
        ...state,
        isReady: true,
        board: action.payload,
      };
    case "failed_loading_board":
      return {
        ...state,
        isReady: true,
        failedLoadingBoard: true,
      };
    case "deleted_board":
      return {
        ...state,
        isReady: true,
        isDeletedBoard: true,
      };
    case "logged_in_user": {
      const user = action.payload as User;
      if (state.user?.isAnonymous === true) {
        // if current user is anonymous, ignore logged in user
        return state;
      }
      const { color, textColor } = randomUserDecoration();
      const boardUser: BoardUser = boardUserSchema.parse({
        ...user,
        isAnonymous: false,
        photoURL: user.thumbnail,
        color,
        textColor,
      });
      return {
        ...state,
        user: boardUser,
        loadingUser: false,
      };
    }
    case "failed_loading_user": {
      return {
        ...state,
        loadingUser: false,
      };
    }
    case "clear_user": {
      return {
        ...state,
        user: null,
      };
    }
    case "anonymous_user": {
      const user = action.payload;
      const boardUser: BoardUser = {
        isAnonymous: true,
        ...user,
      };
      return {
        ...state,
        user: boardUser,
        loadingUser: false,
      };
    }
    case "set_stage_ready": {
      return {
        ...state,
        isStageReady: true,
      };
    }
    case "set_stage_ref": {
      return {
        ...state,
        stageRef: action.payload,
      };
    }
    case "show_signup": {
      return {
        ...state,
        showsSignup: action.payload,
      };
    }
    case "set_anonymous_session":
      return {
        ...state,
        isAnonymous: action.payload,
      };
    case "set_template_elements":
      return {
        ...state,
        templateElementsToCreate: action.payload,
      };
    case "set_pusher_channel":
      return {
        ...state,
        pusherChannel: action.payload,
      };
    default:
      return state;
  }
}

type IBoardContext = [BoardState, React.Dispatch<BoardDispatch>];

export const BoardContext = createContext({} as IBoardContext);

declare global {
  interface Window {
    Board: any;
  }
}
export function OpenBoardProvider({ board, isViewOnly, children }: { board: any; isViewOnly: boolean; children: any }) {
  const { document_id: documentId, user_id: userId, expiry_date } = board;

  const expiryDate = new Date(expiry_date);
  const now = new Date();
  const expiresInHours = (expiryDate.getTime() - now.getTime()) / 1000 / 60 / 60;

  const [state, dispatch] = useReducer(
    reducer,
    boardStateSchema.parse({
      documentId,
      user: {
        ...randomUserDecoration(),
        id: userId,
        isAnonymous: true,
        name: "User",
      },
      config: { isOpenSession: true, isViewOnly },
      expiresInHours,
    })
  );
  const value = useMemo(() => [state, dispatch] as IBoardContext, [state, dispatch]);

  return <BoardContext.Provider value={value}>{children}</BoardContext.Provider>;
}

export function BoardProvider({ documentId, children }: { documentId: string; children: any }) {
  const [ { user, userState, account } ] = useStateValue();
  const [ state, dispatch ] = useReducer(reducer, initialState(documentId));
  const value = useMemo(() => [state, dispatch] as IBoardContext, [state, dispatch]);

  const { board, failedLoading, isDeletedBoard, templateElements } = useBoard(documentId);

  useEffect(() => {
    window.Board = {};
  }, []);

  useEffect(() => {
    window.Board = { ...window.Board, board, account };
    if (board) {
      dispatch({ type: "board_loaded", payload: board });
      if (account) {
        dispatch({ type: "set_anonymous_session", payload: board.accountId !== account.id });
      } else if (userState === LoadedState.failed) {
        dispatch({ type: "set_anonymous_session", payload: true });
      }
    }
    if (failedLoading) {
      dispatch({ type: "failed_loading_board", payload: null });
    }
    if (isDeletedBoard) {
      dispatch({ type: "deleted_board", payload: null });
    }
  }, [board, account, failedLoading, isDeletedBoard]);

  useEffect(() => {
    if (templateElements) {
      dispatch({ type: "set_template_elements", payload: templateElements });
    }
  }, [ templateElements ]);

  useEffect(() => {
    if (userState === LoadedState.failed) {
      dispatch({ type: "failed_loading_user", payload: null });
    } else if (user) {
      dispatch({ type: "logged_in_user", payload: user });
    }
  }, [ user, userState ]);

  return <BoardContext.Provider value={value}>{children}</BoardContext.Provider>;
}

export function useBoardValue() {
  return useContext(BoardContext);
}
